import React from "react";
import styled from "styled-components";
import Layout from "../components/Layout";

import vagUrl from "../documents/VAG_Art_45_Stifani_Finanz_GmbH.pdf";
import mandarUrl from "../documents/Mandat_Stifani_Finanz_GmbH.pdf";

const Toolbar = styled.div`
  flex-direction: row;
`;
const Text = styled.p`
  padding: 10px 10px 10px 10px;
  line-height: 150%;
`;
const Link = styled.a`
  text-decoration: none;
  color: var(--color-secondary);
`;

export default () => (
  <Layout>
    <Toolbar>
      <h1 id="impressum">Impressum</h1>
      <Text>
        <b>Stifani Finanz GmbH</b> <br />
        Lättichstrasse 1 <br />
        CH-6340 Baar <br />
        Tel. +41 (0)78 783 55 24 <br />
        Geschäftsführerin: Stifani Cornelia <br /> <br />
        Handelsregister Nummer : CH-170.4.017.871-9 <br />
        UID - Firmennummer CHE-218.145.633 <br /> <br />
        Copyright © 2022 Stifani Finanz GmbH
      </Text>
      <br />

      <h1 id="datenschutz">Datenschutzerklärung</h1>
      <Text>
        Alle personenbezogenen Daten werden bei uns vertraulich behandelt. Ihre
        persönlichen Daten, die Sie uns im Rahmen einer Kontaktaufnahme zur Verfügung
        stellen, werden bei uns gespeichert und verarbeitet. In der Regel sind es Ihre
        Personalien, E-Mail-Adresse, Anschrift, Telefonnummer oder Telefaxnummer.
        <br /><br />
        Durch die Nutzung dieser Daten, können wir Ihnen attraktive Angebote erstellen und
        abgeben. Die Daten werden durch uns genutzt, diese Daten werden für Offerte
        Angebote auch an unseren ausgewählten Partner mitgeteilt. Wir nutzen Ihre Daten
        nur für eigene Zwecke, um Ihren Bedarf abzudecken und Ihnen die richtigen Offerten
        zu unterbreiten. Die Nutzung Ihrer persönlichen Daten können Sie jederzeit durch
        schriftliche Mitteilung / E-Mail an uns widerrufen.
      </Text>
      <br />

      <h1>Haftungshinweis</h1>
      <Text>
        Wir wählen unsere externen Links sorgfältig aus. Für fremden/externen Links
        übernehmen wir keine Haftung. Die Betreiber der verlinkten Seiten sind
        ausschließlich selbst für dessen Inhalt verantwortlich.
      </Text>
      <br />

      <h1>Urheberrechte</h1>
      <Text>
        Inhalte, Dateien, Bilder, Fotos auf der Webseite Stifani Finanz gehört zu 100% der
        Firma Stifani Finanz GmbH oder den speziell erwähnten Inabern.
        Sämtliche Reproduktion ist untersagt und bedarf einer vorgängigen, schriftlicher
        Zustimmung der Firma Stifani Finanz GmbH.
      </Text>
      <br />

      <h1>Informationspflicht</h1>
      Kunden Informationspflicht nach Versicherungsaufsichtsgesetzt (VAG) Art. 45:&nbsp;
      <Link href={vagUrl} target="_blank">Stifani_Finanz_GmbH_VAG_Art_45.pdf</Link> <br />
      <br />

      <h1>Maklermandat</h1>
      Mandat zum herunterladen:&nbsp;
      <Link href={mandarUrl} target="_blank">Mandat_Stifani_Finanz_GmbH.pdf</Link> <br />
    </Toolbar>
  </Layout>
);
